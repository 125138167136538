<template>
  <p>
  <b>TODO:  Show User Website's Current template with Edit/Change option</b>
  </p>
</template>

<script>
export default {
  name: "UserWebsiteTemplate",
  components: {},
  data() {
    return {
    }
  },
  mounted() {
    this.getTableData();
  },
  methods: {
    async getTableData() {
    },
    performSearch(search) {
    },
    handleDelete(data) {
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getTableData();
    },
    handleSizeChange(val){
      this.getTableData();
    }
  }
}
</script>
<style lang="scss">
</style>
<style lang="scss" scoped>
</style>
